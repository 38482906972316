//@ts-check


import React, { useState, useReducer, useMemo } from 'react';
import Solution from '../Solution/Solution'
import Input from '../Input/Input'
import { defaultHeirs, calculate } from 'proximys-inheritance'

import Fab from '@material/react-fab'
import Button from '@material/react-button'
import Modal from 'react-modal'

// import T from '../translation-en.json'
import { useTranslation } from '../translation'

import '@material/react-button/dist/button.css'
import '@material/react-fab/dist/fab.css'
import './App.css';
import './Modal.css';



Modal.setAppElement('#root')
function prnt() {
  window.print();
}

function redirect() {
  window.location.href = "http://islamkavadam.com";
}


const App = () => {
  const customModalStyles = {
    content: {
      width: '60%',
      height: '30%',
      margin: 'auto',
      backgroundColor: '#f1f1f1',
      borderRadius: '8px',
    }
  };
  const customModalStylesIntro = {
    content: {
      width: '60%',
      height: '70%',
      margin: 'auto',
      backgroundColor: '#f1f1f1',
      borderRadius: '8px',
    }
  };
  const intro = (
    <div>
      <h4 style={{ textAlign: 'center' }}>അനന്തരാവകാശം ഇസ്‌ലാമികമായി കണക്കുകൂട്ടാനുള്ള മലയാളത്തിലെ ആദ്യ സാങ്കേതിക സംവിധാനമാണ് കവാടം അനന്തരാവകാശ കാല്‍ക്കുലേറ്റര്‍
      </h4>
      <ul>
        <li>ഒരാള്‍ മരിച്ചു കഴിഞ്ഞാല്‍ അയാള്‍ കൈവശം വെച്ചിരുന്ന സമ്പത്ത് എന്തു ചെയ്യണം? ഇക്കാര്യത്തില്‍ കുറ്റമറ്റ, ഏറ്റവും പ്രായോഗിക നിയമം ഇസ്‌ലാമിന്റേതാണ്.</li>
        <li>വിജ്ഞാനത്തിന്റെ പകുതിയാണ് എന്ന് മുഹമ്മദ് നബി(സ്വ) പറഞ്ഞ (ഇബ്‌നുമാജ) അനന്തരാവകാശ നിയമത്തെപ്പറ്റി പക്ഷേ മുസ്‌ലിം പണ്ഡിതന്മാരില്‍ പോലും പലര്‍ക്കും അറിയില്ലo</li>
        <li>മദ്ഹബുകള്‍ തമ്മിലോ വിവിധ മുസ്‌ലിം ഗ്രൂപ്പുകള്‍ തമ്മിലോ അഭിപ്രായാന്തരമില്ലാത്ത കാര്യമാണ് അനന്തരാവകാശ നിയമങ്ങളും അതിലെ ഓഹരിനിര്‍ണയവും.</li>
        <li>പണ്ഡിതന്മാര്‍ക്കും സാധാരണക്കാര്‍ക്കും  ഇസ്‌ലാമിലെ ദായക്രമം വളരെ ലളിതമായി കണക്കുകൂട്ടാന്‍ കഴിയുന്ന വിധമാണ് കാല്‍ക്കുലേറ്റര്‍ സംവിധാനിച്ചിരിക്കുന്നത</li>
        <li>ഖുര്‍ആന്‍ ആയത്തുകളും നബിവചനങ്ങളും മറ്റ് ആധികാരിക ഗ്രന്ഥങ്ങളുമാണ് ഈ കാല്‍ക്കുലേറ്ററിന്റെ നിര്‍മിതിക്കാധാരം.</li>
        <li>മരണപ്പെട്ട വ്യക്തിയുടെ ജീവിക്കുന്ന അവകാശികള്‍ ആരൊക്കെ എന്ന് കാണിച്ചാല്‍ ഓരോരുത്തര്‍ക്കും ലഭിക്കാവുന്ന ഓഹരികള്‍/വിഹിതം തെളിഞ്ഞുവരുന്ന തരത്തിലാണ് രൂപകല്പന ചെയ്തിരിക്കുന്നത്.</li>
        <li>അര്‍ഹതയില്ലാത്ത അവകാശികളെ തിരഞ്ഞെടുത്താല്‍ അവര്‍ക്ക് അവകാശം കാണിക്കുന്നതല്ല</li>
        <li>ശരിയായ അവകാശികളെ അറിയാനും കൂടുതല്‍ മനസ്സിലാക്കാനും വെബ്‌സൈറ്റിലെ അനന്തരാവകാശം എന്ന ഭാഗം സന്ദര്‍ശിക്കുക</li>
      </ul>
      <Button
        id="non-printable"
        unelevated
        className="App-close-modal-button"
        onClick={() => next()}>
        അടുത്തത്
      </Button>
    </div>
  );
  const secondIntro = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90%', textAlign: 'center' }}>
      <h4 style={{ textAlign: 'center' }}>മാതാപിതാക്കളും അടുത്ത ബന്ധുക്കളും വിട്ടേച്ചു പോയ ധനത്തില്‍ പുരുഷന്‍മാര്‍ക്ക് ഓഹരിയുണ്ട്; സ്ത്രീകള്‍ക്കും ഓഹരിയുണ്ട്. ആ ധനം കുറച്ചാകട്ടെ, കൂടുതലാകട്ടെ. അത് നിര്‍ണയിക്കപ്പെട്ട ഓഹരിയാകുന്നു (ഖുര്‍ആന്‍: 4:7)</h4>

      <Button
        id="non-printable"
        unelevated
        className="App-close-modal-button"
        onClick={() => closeIntro()}>
        തുടങ്ങാം
      </Button>
    </div>
  );
  const modalContent = (
    <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90%', textAlign: 'center' }}>
      <h4 style={{ textAlign: 'center' }}>മരണപ്പെട്ടത് പുരുഷനാണോ സ്ത്രീയാണോ ?</h4>
      <Button
        id="non-printable"
        unelevated
        className="App-close-modal-button"
        onClick={() => mortalSet('husband')}>
        പുരുഷൻ
      </Button>
      <Button
        id="non-printable"
        unelevated
        className="App-close-modal-button"
        onClick={() => mortalSet('wife')}>
        സ്ത്രീ
      </Button>
    </div>
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenIntro, setIsOpenIntro] = useState(true);
  const [isOpenSecondIntro, setIsOpenSecondIntro] = useState(false);

  const t = useTranslation()
  const [heirs, dispatch] = useHeirInput()
  const results = useMemo(() => calculate(heirs), [heirs])
  const [solutionModalIsOpen, setSolutionModalIsOpen] = useState(false)

  const [husband, setHusband] = useState(true);
  const [wife, setWife] = useState(true);

  const mortalSet = (string) => {
    console.log(string);
    if (string === 'husband') {
      setHusband(false);
    } else {
      setWife(false);
    }
    setIsOpen(false);

  };

  const next = () => {
    setIsOpenIntro(false);
    setIsOpenSecondIntro(true);
  };


  const closeIntro = () => {
    setIsOpenIntro(false);
    setIsOpenSecondIntro(false);
    setIsOpen(true);
  };


  const back = () => {
    setSolutionModalIsOpen(false)
  }
  const closeAndReset = () => {
    dispatch({ type: 'reset' })
    setSolutionModalIsOpen(false)
    window.location.reload();

  }

  let [total, setData] = useState('')
  return (


    <div className="App-container">
      <header className="App-header">
        <img src="/head_total.png" alt="logo" width="340px" height="111.9px" />

      </header>
      <main id="non-printable" className="App-main" dir={t('lang') === 'ar' ? 'rtl' : 'ltr'}>


        <Input heirs={heirs} dispatch={dispatch} total={total} setData={setData} husband={husband} wife={wife} />


        <Fab
          onClick={() => setSolutionModalIsOpen(true)}
          className="App-calculate-button"
          textLabel={t('calculate')}
        />
        <Fab
          onClick={redirect}
          className="App-more-button"
          textLabel={t('know_more')}
        />


        <Modal
          isOpen={solutionModalIsOpen}
          className="App-modal"
          overlayClassName="App-overlay"
          onRequestClose={() => setSolutionModalIsOpen(false)}
        >
          <Solution id="printable" results={results} total={total} input_heirs={heirs} />
          <p id="demo"></p>
          <Button
            id="non-printable"
            unelevated
            className="App-close-modal-button"
            onClick={back}>
            {t('back')}
          </Button>
          <Button
            id="non-printable"
            unelevated
            className="App-close-modal-button"
            onClick={closeAndReset}>
            {t('close')}
          </Button>
          <Button
            id="non-printable"
            unelevated
            className="App-close-modal-button"
            onClick={prnt}>
            {t('print')}
          </Button>
        </Modal>
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={customModalStylesIntro}>
          {modalContent}
        </Modal>
        <Modal isOpen={isOpenIntro} onRequestClose={() => { setIsOpenIntro(false); setIsOpen(true); }} style={customModalStylesIntro}>
          {intro}
        </Modal>
        <Modal isOpen={isOpenSecondIntro} onRequestClose={() => { setIsOpenSecondIntro(false); setIsOpen(true); }} style={customModalStylesIntro}>
          {secondIntro}
        </Modal>
      </main>
    </div>
  );
}


export default App;



const useHeirInput = () => {
  const initialState = defaultHeirs
  const reducer = (state, action) => {
    switch (action.type) {
      case 'change_count':
        if (action.count > 0) {
          if (action.heir === 'husband') {
            return { ...state, wife: 0, [action.heir]: action.count, total: action.total }
          }
          if (action.heir === 'wife') {
            return { ...state, husband: 0, [action.heir]: action.count, total: action.total }
          }
        }
        return { ...state, [action.heir]: action.count, total: action.total }
      case 'reset':
        return initialState
      default:
        throw Error('unknown action type')
    }
  }
  return useReducer(reducer, initialState)
}